if(lastUrlPara == "my-profile") {
    $("#themeid #checkAll").click(function () {
        if ($("#themeid #checkAll").is(':checked')) {
            $("#themeid input[type=checkbox]").each(function () {
                $(this).prop("checked", true);
            });

        } else {
            $("#themeid input[type=checkbox]").each(function () {
                $(this).prop("checked", false);
            });
        }
    });   

    $("#regions #checkAll1").click(function () {
        if ($("#regions #checkAll1").is(':checked')) {
            $("#regions input[type=checkbox]").each(function () {
                $(this).prop("checked", true);
            });

        } else {
            $("#regions input[type=checkbox]").each(function () {
                $(this).prop("checked", false);
            });
        }
    });  

    $('#sm_image_div input').on('change', function (event) {
        var elmentId = event.target.id;
        var ext = $('#' + elmentId).val().split('.').pop().toLowerCase();
        if ($.inArray(ext, ['png', 'jpg', 'jpeg']) == -1) {
            alert('invalid extension!');
            return false;
        } else {
            readURL(this, elmentId);
        }
    });

    function readURL(input, elmentId) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#sm_image1_filediv').html("");
                $('#sm_image1_filediv').html('<img class="img-fluid" src="' + e.target.result + '">');
                $('#sm_image1_filediv').val(e.target.result);
                $("#pullRemove").show();
            }
            reader.readAsDataURL(input.files[0]);
        }
    }
}



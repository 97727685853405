$(document).ready(function () {
    var path = appURL + "/autocomplete";
    
    $('#header-search-resource').autocomplete({
        source: function (query, process) {
            return $.get(path, { query: query.term }, function (data) {
                var resp = $.map(data, function (obj) {
                    return obj.tag.replace(/\\/g, "");
                });
                return process(resp);
            });
        },
        open: function (event, ui) {
            autocompleteElement = event.target;
            console.log(autocompleteElement)
            $('.ui-autocomplete').css('width', $(autocompleteElement).outerWidth() + 'px')
        },
        select: function (event, ui) {
            
                $('#header-search-resource').val(ui.item.value);
                
                document.valueSelectedForAutocomplete = ui.item.value;
                $(".header-search-btn").trigger("click");
            
        }
    });

    $('#header-search-resource').on('keydown', function(e) {
        if (e.which == 13) {
            $( ".tabs-resources" ).each(function( index ) {
                if($(this).hasClass('active'))
                {
                    var tabS = $(this).attr('data-tabK');
                   
                    $('#header-search-box').attr('action',headerSearchURL);
                    $('#header-search-box').submit();
                }
            });
            //$('#header-search-box').submit();
        }
    });

    $('#header_search_res').on('click', function(e){
        $('#header-search-resource').val('');
    });

});

function socialShareLink(id) {
    var share_url = "";
    var desc= "";
    var current_url = window.location.href;
    
    $('meta[name=og_url]').attr('content', current_url);

    var meta_title = 'YouthForesight';
    
    if (id === "fb_link") {
        share_url = "https://www.facebook.com/share.php?u=" + encodeURIComponent(current_url) + "&t=" + meta_title+"&description="+desc;
    } else if (id === "linkedin_link") {
        share_url = "http://www.linkedin.com/shareArticle?url=" + encodeURIComponent(current_url) + "&title=" + meta_title;
    } else if (id === "twitter_link") {
        share_url = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(current_url) + "&text=" + encodeURIComponent(meta_title);
    }
    
    window.open(share_url);
    return false;
}

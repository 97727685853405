if(thirdUrlPara == "resource-details") {
    $(document).ready(function() {
        
        $("#load_more_related_resource").click(function() {
            
            var page_number = $("input[name='page_number']").val();
            var page_data = $("input[name='page_page']").val();
            var resource_id = $("input[name='resource_id']").val();
            var redirect_link = $("input[name='resource_id']").val();
            page_number++;
            $("input[name='page_number']").val(page_number);
            $.ajax({
                type: "GET",
                url: appURL + "/my-related-resources-ajax",
                data: $("#resourceFilterAll").serialize(), // serializes the form's elements.
                success: function(data) {
                    console.log("data========>",data);
                    var countT = $("input[name='related_cnt']").val();

                    var pageCnt = parseInt(page_number * 2);

                    if ((pageCnt + 2) <= parseInt(countT)) {
                        $('#load_more_related_resource').css('display', 'block');
                    } else {
                        $('#load_more_related_resource').css('display', 'none');
                    }
                    $('#relateddata').append(data);

                }
            });

        });

    });
}
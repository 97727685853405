$(document).ready(function () {
    $("#reset-new-password").validate({
        rules: {
            password2: {
                required: true,
                //pwcheck: true,
                //minlength: 8
            },
            password_confirm: {
                required: true,
                //minlength : 8,
                equalTo : "#password2"
            }
        },
        messages: {
            password2: {
                required: "Please enter a password",
                //pwdcheck: "Doesn't match password criteria",
                //minlength: "Doesn't match password criteria"
            },
            password_confirm: {
                required: "Please enter a confirm password",
                equalTo: "Password doesn't match"
            },
        }
    });
    
    $('#password2').keyup(function() {

        var values = $('#password2').val();
        var alphaExp = /^[a-zA-Z]+$/;
        //Password length
        if($('#password2').val().length >= 8)
        {
            $('#lengthPass1').attr('style', 'color: #40a650');
        }
        else
        {
            $('#lengthPass1').attr('style', 'color: #292b2c');
        }
        //            ///Password should contain 1 small alphabet
        if (/[a-z]/.test(values)) {
            $('#one_small_letter1').attr('style', 'color: #40a650');
        }
        else {
            $('#one_small_letter1').attr('style', 'color: #292b2c');
        }
        //            ///Password should contain 1 capital alphabet
        if (/[A-Z]/.test(values)) {
            $('#one_capital_letter1').attr('style', 'color: #40a650');
        }
        else {
            $('#one_capital_letter1').attr('style', 'color: #292b2c');
        }
        //Password should contain 1 number
        if (/\d/.test(values)) {                 
            $('#onenumber1').attr('style', 'color: #40a650');
        }
        else
        {
            $('#onenumber1').attr('style', 'color: #292b2c');
        }
        ///Password should contain 1 special character
        if (/\W|_/g.test(values)) {                 
            $('#onespecial1').attr('style', 'color: #40a650');
        }
        else
        {
            $('#onespecial1').attr('style', 'color: #292b2c');
        }
    });  
});
$(document).ready(function () {


	if ($("#add_resource").length == 1) {
		var formValidatator = new Object();
		$('.defaultshow').show();

		$("#resource_type").on("change", function () {
			formValidatator.resetForm();
			$('#add_resource').removeData('validator');

			//console.log($(this).val());
			if ($(this).val() == "Tools") {
				$('.tools_field').show();
				formValidatator = toolsForm();
			}

			if ($(this).val() == "Publications") {
				$('.publication_field').show();
				formValidatator = publicationForm();
			}

			if ($(this).val() == "Policy") {
				//$('.tools_field').show();
				formValidatator = policyForm();
			}

			if ($(this).val() == "Platform") {
				//$('.tools_field').show();
				formValidatator = platformForm();
			}

			if ($(this).val() == "Videos, Webinars, or Online Courses" || $(this).val() == "Videos Webinars Online Courses") {
				$('.video_field').show();
				formValidatator = videoForm();
			}
			if ($(this).val() == "Blogs") {
				//$('.video_field').show();
				formValidatator = blogForm();
			}

			if ($(this).val() == "Event") {
				//$('.video_field').show();
				formValidatator = eventForm();
			}

		});

		$("#resource_sub_type").on("change", function () {
			if ($("#resource_sub_type option:selected").text() == "Podcast") {
				$( "#video_url" ).rules( "remove", "youtubeValid" );
			} else {
				$( "#video_url" ).rules( "add", "youtubeValid" );
			}
		});

		formValidatator = defaultshowForm();
	}

	if ($("#edit_resource").length == 1) {

		if (($.trim($("#resource_type option:selected").text()) == 'Tools')
			|| ($.trim($("#resource_type option:selected").text()) == 'tools')
			|| ($.trim($("#resource_type option:selected").text()) == 'Tool')) {
			toolsEditForm();
		} else if (($.trim($("#resource_type option:selected").text()) == 'Publications')
			|| ($.trim($("#resource_type option:selected").text()) == 'publications')
			|| ($.trim($("#resource_type option:selected").text()) == 'Publication')) {
			publicationEditForm();
		} else if (($.trim($("#resource_type option:selected").text()) == 'Policy')
			|| ($.trim($("#resource_type option:selected").text()) == 'policy')) {
			policyEditForm();
		} else if (($.trim($("#resource_type option:selected").text()) == 'Platform')
			|| ($.trim($("#resource_type option:selected").text()) == 'platform')) {
			platformEditForm();
		} else if (($.trim($("#resource_type option:selected").text()) == 'Videos, Webinars, or Online Courses') 
		|| ($.trim($("#resource_type option:selected").text()) == 'Videos Webinars Online Courses')
		|| ($.trim($("#resource_type option:selected").text()) == 'Multimedia')) {
			videoEditForm();
		} else if (($.trim($("#resource_type option:selected").text()) == 'Blogs')
			|| ($.trim($("#resource_type option:selected").text()) == 'blogs')
			|| ($.trim($("#resource_type option:selected").text()) == 'Blog')) {
			blogEditForm();
		} else if (($.trim($("#resource_type option:selected").text()) == 'Event')
			|| ($.trim($("#resource_type option:selected").text()) == 'Event')) {
			eventEditForm();
		} else {
			formValidatator = defaultshowEditForm();
		}

		$("#resource_sub_type").on("change", function () {
			if ($("#resource_sub_type option:selected").text() == "Podcast") {
				$( "#video_url" ).rules( "remove", "youtubeValid" );
			}
		});
	}

	$("#save_publish").on("click", function () {
		if ($("#resource_sub_type option:selected").text() == "Podcast") {
			$( ".video_url" ).rules( "remove", "youtubeValid" );
		}	
	});

	$("#video_url").on("keyup", function () {
		if ($("#resource_sub_type option:selected").text() == "Podcast") {
			$( ".video_url" ).rules( "remove", "youtubeValid" );
		}	
	});
});

/*Start Add here*/
function defaultshowForm() {

	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			year_of_publication: "required",
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			"source[]": "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please select year of publication",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			"source[]": "Please select source"
		}
	});
}

function defaultshowForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},

			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function defaultshowEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},

			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}
function toolsForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			"selectCountries[]": "required",
			audience: "required",
			page: {
				required: true,
				numericOnly: true
			},
			// "link[]": "required",
			urlTitle: "required",
			topic: "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			// quote: {
			// 	required: true,
			// 	wordCount: [2, 50]
			// },
			// how_does_it_work: {
			// 	required: true,
			// 	wordCount: [2, 50]
			// },
			// why_it_is_useful: {
			// 	required: true,
			// 	wordCount: [2, 50]
			// },
			"partners[]": "required",
			"source[]": "required",
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			topic: "Please select topic",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			//time: "Please enter valid time",
			audience: "Please enter valid audience",
			/*introduction : {
				required : "Please enter valid introduction"
			},	*/
			page: {
				required: "Please enter valid page",
				numericOnly: "Please only enter numeric values (0-9)"
			},
			// "link[]": "Please enter valid links",
			urlTitle: "Please enter valid url Title",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			// quote: {
			// 	required: "quote field is required",
			// 	wordCount: "quote should not be more than 50 words long"
			// },
			// how_does_it_work: {
			// 	required: "How does it work field is required",
			// 	wordCount: "How does it work should not be more than 50 words long"
			// },
			// why_it_is_useful: {
			// 	required: "Why it is useful field is required",
			// 	wordCount: "Why it is useful should not be more than 50 words long"
			// },
			//privacy :  "Please select privacy",

			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function publicationForm() {
	console.log("inside publication form");
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			highlights : {
				wordCount: [2, 150]
			},
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				//maxlength: "Title should not be more than 150 words long"
			},
			/*year_of_publication : "Please select year of publication",*/
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			highlights : {
				wordCount : "Highlights should not be more than 150 words long"
			},

			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function policyForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},

			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function platformForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},

			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function blogForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*start_date : "required",*/
			//"selectCountries[]": "required",
			//is_featured : "required",
			/*what_is_it : {
				required : true,
				wordCount: [2, 150]
			},*/
			/*description : {
				required : true,
				wordCount: [2, 100]
			},*/

			//"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			//"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			/*start_date : "Please add published date",*/
			//"selectCountries[]" : "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			/*what_is_it :  {
				required : "What is it field is required"
			},*/
			/*description :  {
				required : "description field is required"
			},*/
			//"partners[]" : "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			//"source[]" : "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function videoForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},

			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			/*watch_it_if : {
				required : true,
				wordCount: [2, 60]
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required",
			video_url: {
				//required : true,
				youtubeValid: true
			}
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			/*year_of_publication : "Please select year of publication",*/
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			/*watch_it_if : {
				required : "The watch it if field is required."
			},*/
			video_url: {
				//required : "The video url field is required."
			},
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

/*Start Edit here*/
function toolsEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			//time: "required",
			audience: "required",
			/*introduction :  {
				required : true,
				wordCount: [2, 50]
			},*/
			page: {
				required: true,
				numericOnly: true
			},
			// links: "required",
			urlTitle: "required",
			topic: "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			// quote: {
			// 	required: true,
			// 	wordCount: [2, 50]
			// },
			// how_does_it_work: {
			// 	required: true,
			// 	wordCount: [2, 50]
			// },
			// why_it_is_useful: {
			// 	required: true,
			// 	wordCount: [2, 50]
			// },
			//privacy : "required",
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			topic: "Please select topic",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			//time: "Please enter valid time",
			audience: "Please enter valid audience",
			/*introduction : {
				required : "Please enter valid introduction"
			},*/
			page: {
				required: "Please enter valid page",
				numericOnly: "Please only enter numeric values (0-9)"
			},
			// links: "Please enter valid links",
			urlTitle: "Please enter valid url Title",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			// quote: {
			// 	required: "quote field is required",
			// 	wordCount: "quote should not be more than 50 words long"
			// },
			// how_does_it_work: {
			// 	required: "How does it work field is required",
			// 	wordCount: "How does it work should not be more than 50 words long"
			// },
			// why_it_is_useful: {
			// 	required: "Why it is useful field is required",
			// 	wordCount: "Why it is useful should not be more than 50 words long"
			// },
			//privacy :  "Please select privacy",

			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function publicationEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			highlights : {
				wordCount: [2, 150]
			},
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			/*year_of_publication : "Please select year of publication",*/
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			highlights : {
				wordCount : "Highlights should not be more than 150 words long"
			},

			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function policyEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},

			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function platformEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},

			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			year_of_publication: "Please add published date",
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function blogEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*start_date : "required",*/
			//"selectCountries[]": "required",
			//is_featured : "required",
			/*what_is_it : {
				required : true,
				wordCount: [2, 150]
			},*/
			/*description : {
				required : true,
				wordCount: [2, 100]
			},*/

			//"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true,
				minlength : 3,

				//wordCount: 10
			},*/
			//"region_id[]": "required",
			//"source[]": "required",
			/*tag : "required",*/
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			/*start_date : "Please add published date",*/
			//"selectCountries[]" : "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			/*what_is_it :  {
				required : "What is it field is required"
			},*/
			/*description :  {
				required : "description field is required"
			},*/
			//"partners[]" : "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			//"region_id[]" : "Please select region",
			//"source[]" : "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function videoEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			/*year_of_publication : "required",*/
			"selectCountries[]": "required",
			//is_featured : "required",
			what_is_it: {
				required: true,
				wordCount: [2, 200]
			},
			/*watch_it_if : {
				required : true,
				wordCount: [2, 60]
			},*/
			"partners[]": "required",
			//"authorids[]": "required",
			//"theme_id[]": "required",
			/*section : {
				required : true
			},*/
			//"region_id[]": "required",
			"source[]": "required",
			/*tag : "required",*/
			terms: "required",
			video_url: {
				//required : true,
				youtubeValid: true
			}
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			/*year_of_publication : "Please select year of publication",*/
			"selectCountries[]": "Please select Region/Country",
			//is_featured : "select one option from the drop down list.",
			what_is_it: {
				required: "What is it field is required",
				wordCount: "What is it should not be more than 200 words long"
			},
			"partners[]": "Please select partners",
			//"authorids[]" : "Please select authors",
			//"theme_id[]" : "Please select themes",
			/*section : {
				required : "Please enter valid section"
			},*/
			/*watch_it_if : {
				required : "The watch it if field is required."
			},*/
			video_url: {
				//required : "The video url field is required."
			},
			//"region_id[]" : "Please select region",
			"source[]": "Please select source",
			/*tag : "Please enter comma separated tags",*/
			terms: "Please accept the terms set out above"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}



$.validator.addMethod('numericOnly', function (value) {
	return /^[0-9]+$/.test(value);
}, 'Please only enter numeric values (0-9)');


var youtubePattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

jQuery.validator.addMethod("youtubeValid", function (value) {
	if (value.length == 0) {
		//console.log("value.length------>",value.length);
		return true;
	}
	return youtubePattern.test(value);
}, "Must be a valid Youtube video URL");

function eventForm() {
	return $("#add_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			event_start_date: "required",
			event_end_date: {
				required: true,
				greaterThan: "#event_start_date"
			},
			// start_time: "required",
			// end_time: {
			// 	required: true,
			// 	greaterThanStartTime: "#end_time"
			// },
			// timezone: "required",
			event_format: {
				required: true,
				wordCount: [1, 20]
			},
			"selectCountries[]": "required",
			"partners[]": "required",
			"eventsource[]": "required",
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			event_start_date: "Start date is required",
			event_end_date: {
				required: "End date is required",
				greaterThan: "Must be greater than or equal to start date"
			},
			// start_time: "Start time is required",
			// end_time: {
			// 	required: "End time is required",
			// 	greaterThanStartTime: "Must be greater than start time"
			// },
			// timezone: "Timezone is required",
			event_format: {
				required: "Event format is required",
				wordCount: "Event format should not be more than 20 words long"
			},
			terms: "Please accept the terms set out above",
			"eventsource[]": "Please select source",
			"selectCountries[]": "Please select Region/Country",
			"partners[]": "Please select partners"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			// $('html, body').animate({
			// 	scrollTop: $(validator.errorList[0].element).offset().top - 100
			// }, 2000);

		}
	});
}

function eventEditForm() {
	return $("#edit_resource").validate({
		ignore: ':hidden:not(textarea)',
		rules: {
			resource_type_id: "required",
			resource_sub_type_id: "required",
			title: {
				required: true,
				minlength: 3,
				maxlength: 150
			},
			event_start_date: "required",
			event_end_date: {
				required: true,
				greaterThan: "#event_start_date"
			},
			// start_time: "required",
			// end_time: {
			// 	required: true,
			// 	greaterThanStartTime: "#end_time"
			// },
			// timezone: "required",
			event_format: {
				required: true,
				wordCount: [1, 20]
			},
			"selectCountries[]": "required",
			"partners[]": "required",
			"eventsource[]": "required",
			terms: "required"
		},
		messages: {
			resource_type_id: "Please select resource type",
			resource_sub_type_id: "Please select resource sub type",
			title: {
				required: "Title is required",
				minlength: "Please enter valid title",
				maxlength: "Title should not be more than 150 words long"
			},
			event_start_date: "Start date is required",
			event_end_date: {
				required: "End date is required",
				greaterThan: "Must be greater than or equal to start date"
			},
			// start_time: "Start time is required",
			// end_time: {
			// 	required: "End time is required",
			// 	greaterThanStartTime: "Must be greater than start time"
			// },
			// timezone: "Timezone is required",
			event_format: {
				required: "Event format is required",
				wordCount: "Event format should not be more than 20 words long"
			},
			terms: "Please accept the terms set out above",
			"eventsource[]": "Please select source",
			"selectCountries[]": "Please select Region/Country",
			"partners[]": "Please select partners"
		},
		focusInvalid: false,
		invalidHandler: function (form, validator) {

			if (!validator.numberOfInvalids())
				return;

			$('html, body').animate({
				scrollTop: $(validator.errorList[0].element).offset().top - 100
			}, 2000);

		}
	});
}

jQuery.validator.addMethod("greaterThan",
	function (value, element, params) {

		if (!/Invalid|NaN/.test(new Date(value))) {
			return new Date(value) >= new Date($(params).val());
		}

		return isNaN(value) && isNaN($(params).val())
			|| (Number(value) >= Number($(params).val()));
	}, 'Must be greater than or equal to {0}.');

// jQuery.validator.addMethod("greaterThanStartTime",
// 	function (tm) {
// 		var ampm = tm.substr(-2)
// 		var clk = tm.substr(0, 5);
// 		var m = parseInt(clk.match(/\d+$/)[0], 10);
// 		var h = parseInt(clk.match(/^\d+/)[0], 10);
// 		h += (ampm.match(/pm/i)) ? 12 : 0;
// 		return h * 60 + m;
// 	}, 'Must be greater than {0}.');

$.validator.addMethod('wordCount', function (value, element, params) {
	//var regex = /\s+/gi;
	//var typedWords = value.trim().replace(regex, ' ').split(' ').length;
	//var typedWords = jQuery.trim(value).replace(regex, ' ').split(' ').length;
	var typedWords = jQuery.trim(value).split(/[\s]+/gi).filter(Boolean).length;

	console.log("count", typedWords);
	return value == '' || (typedWords >= params[0] && typedWords <= params[1]);
}, jQuery.validator.format('Must be between {0} to {1} words allowed.'));

//add the custom validation method
// jQuery.validator.addMethod("wordCount",
//    function(value, element, params) {
//       var count = getWordCount(value);
//       if(count >= params[0]) {
//          return true;
//       }
//    },
//    jQuery.validator.format("A minimum of {0} words is required here.")
// );

$("#add_resource #save").click(function () {
	setTimeout(function () {
		if ($("#add_resource").find('label.error:visible').length  > 0){
			$('html, body').animate({
				scrollTop: 0
			}, 2000);
		}
	}, 0);
})

function getWordCount(wordString) {
	var words = wordString.split(" ");
	words = words.filter(function (words) {
		return words.length > 0
	}).length;
	return words;
}
$(document).ready(function () {
    $('#all-filters-collapse').on('hide.bs.collapse', function () {
        $("[data-target=#all-filters-collapse]").text("More Filters");
    });
    $('#all-filters-collapse').on('show.bs.collapse', function () {
        $("[data-target=#all-filters-collapse]").text("Clear All Filters");
        $(".clear-all-filters").removeClass("d-none");
        $(".more-filters").hide();
    });
    $(".clear-all-filters").on('click', function () {
        $('#resourceFilter').get(0).reset();
        $(".form-control-multiselect").multiselect('clearSelection');
        window.location.href = appURL + "/resource-search";
    })
    $('#read-more-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.link-arrow').hide();
        $(this).siblings('.link-up-arrow').removeClass('d-none');
        $(this).siblings('.link-up-arrow').show();
    });
    $('#read-more-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.link-up-arrow').hide();
        $(this).siblings('.link-arrow').show();
    });
});

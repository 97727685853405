var searchURLName = "resource-search";
if (lastUrlPara.indexOf(searchURLName) != -1) {
    $(document).ready(function () {
        $(document).on('click', '.carousel-control-prev, .carousel-control-next', function (e) {
            var something = $(this);
            $(this).attr('href', '#carouselExampleIndicators3');
            e.preventDefault();
            $(this).removeAttr('href');
            setTimeout(function () {
                something.attr('href', '#carouselExampleIndicators3');
            }, 3000);
        });

    });

    $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        return decodeURI(results[1]) || 0;
    }

    /****** Search Filter ****/
    function filterApply(chgFilt) {
        $("#taboverlay").show();
        $.ajax({
            url: appURL + "/ajax-resource-filter-count",
            data: $("#resourceFilter").serialize(),
            dataType: 'json',
            success: function (data) {
                //console.log("data==========>", data);
                $("#taboverlay").hide();
                $("#publishedCnt").html(data.publishedCnt);
                //$("#blogsCnt").html(data.blogsCnt + data.news_blogCnt);
                $("#eventsCnt").html(data.eventsCnt);
                $("#multimediaCnt").html(data.multimediaCnt);
                //$("#news_blogCnt").html(data.blogsCnt + data.news_blogCnt);
                $("#newsblogsCnt").html(data.newsblogsCnt);
                $("#platformCnt").html(data.platformCnt);
                $("#policyCnt").html(data.policyCnt);
                $("#toolsCnt").html(data.toolsCnt);
                $("#allCnt").html(data.allCnt);

                $("#all_cnt").val(data.allCnt);
                $("#events_cnt").val(data.eventsCnt);
                //$("#news_cnt").val(data.blogsCnt + data.news_blogCnt);
                $("#news_cnt").val(data.newsblogsCnt);
                $("#multimedia_cnt").val(data.multimediaCnt);
                $("#tools_cnt").val(data.toolsCnt);
                $("#platform_cnt").val(data.platformCnt);
                $("#publication_cnt").val(data.publishedCnt);
                $("#policy_cnt").val(data.policyCnt);
                // $(".all .nav-link").trigger("click");

                $(".fliter-by-section, .filter-tabs-content").show();

                if ((parseInt(data.publishedCnt) == 0) && (parseInt(data.policyCnt) == 0) && (parseInt(data.toolsCnt) == 0) && (parseInt(data.multimediaCnt) == 0) && (parseInt(data.newsblogsCnt) == 0) && (parseInt(data.eventsCnt) == 0) && (parseInt(data.allCnt) == 0)) {
                    $("#nodata").show();
                    $("#nodata").html("No Data Found!");
                } else {
                    $("#nodata").hide();
                }

                if (parseInt(data.publishedCnt) == 0) {
                    $('#publication-div').hide();
                    $('#publication-link').hide();
                } else {
                    $('#publication-link').show();
                    // $('#publication-div').show();
                }

                if (parseInt(data.policyCnt) == 0) {
                    $('#policy-div').hide();
                    $('.policy').hide();
                } else {
                    $('.policy').show();
                    // $('#policy-div').show();
                }

                if (parseInt(data.platformCnt) == 0) {
                    $('#platform-div').hide();
                    $('.platform').hide();
                } else {
                    $('.platform').show();
                    // $('#platform-div').show();
                }

                if (parseInt(data.toolsCnt) === 0) {
                    $('#tools-div').hide();
                    $('.tools').hide();
                } else {
                    $('.tools').show();
                    // $('#tools-div').show();
                }

                if (parseInt(data.multimediaCnt) == 0) {
                    $('#multimedia-div').hide();
                    $('.multimedia').hide();
                } else {
                    $('.multimedia').show();
                    // $('#multimedia-div').show();
                }

                if (parseInt(data.newsblogsCnt) == 0) {
                    $('#new-blog-div').hide();
                    $('.newsblog').hide();
                } else {
                    $('.newsblog').show();
                    // $('#new-blog-div').show();
                }

                if (parseInt(data.eventsCnt) == 0) {
                    $('#events-div').hide();
                    $('.events').hide();
                } else {
                    $('.events').show();
                    // $('#events-div').show();
                }

                if (parseInt(data.allCnt) == 0) {
                    $('.all').hide();
                    $('#all-div').hide();
                } else {
                    $('.all').show();
                    // $('#all-div').show();
                }

                AOS.refresh();
                AOS.refreshHard();

                if (parseInt(data.publishedCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_publication').show();
                } else {
                    $('#load_more_publication').hide();
                }
                if (parseInt(data.eventsCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_events').show();
                } else {
                    $("#load_more_events").hide();
                }
                if (parseInt(data.multimediaCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_multimedia').show();
                } else {
                    $("#load_more_multimedia").hide();
                }
                if (parseInt(data.newsblogsCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_news').show();
                } else {
                    $("#load_more_news").hide();
                }
                
                if (parseInt(data.platformCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_platform').show();
                } else {
                    $("#load_more_platform").hide();
                }
                if (parseInt(data.policyCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_policy').show();
                } else {
                    $("#load_more_policy").hide();
                }
                if (parseInt(data.toolsCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_tools').show();
                } else {
                    $("#load_more_tools").hide();
                }

                if (parseInt(data.allCnt) > parseInt(data.recordCnt)) {
                    $('#load_more_all').show();
                } else {
                    $("#load_more_all").hide();
                }

                var urlTab = $.urlParam('tab');

                if (urlTab != null && $("." + urlTab).is(":visible")) {
                    $("." + urlTab).trigger("click");
                } else {
                    $("#all-nav").trigger("click");
                }

            }
        });

        // call next ajax function
        var url = appURL + "/ajax-resource-filter-search";

        $.ajax({
            //type: "POST",
            url: url,
            data: $("#resourceFilter").serialize(), // serializes the form's elements.
            dataType: 'json',
            success: function (data) {
                $("#tools-div-inner").html(data.toolsCompiled);
                $("#publication-div-inner").html(data.publishedCompiled);
                $("#new-blog-div-inner").html(data.blogsCompiled);
                $("#events-div-inner").html(data.eventsCompiled);
                $("#multimedia-div-inner").html(data.multimediaCompiled);
                $("#platform-div-inner").html(data.platformCompiled);
                $("#policy-div-inner").html(data.policyCompiled);
                $("#all-div-inner").html(data.allResources);
                $("#google-search").html(data.googleSearchCompiled);

                $('#' + "tools" + '_page').val(0);
                $('#' + "publication" + '_page').val(0);
                $('#' + "new-blog" + '_page').val(0);
                $('#' + "events" + '_page').val(0);
                $('#' + "multimedia" + '_page').val(0);
                $('#' + "platform" + '_page').val(0);
                $('#' + "policy" + '_page').val(0);
                $('#' + "all" + '_page').val(0);                
            }
        });

        // call google search ajax function
        var googleUrl = appURL + "/ajax-resource-google-search";

        $.ajax({
            url: googleUrl,
            data: $("#resourceFilter").serialize(), // serializes the form's elements.
            dataType: 'json',
            success: function (data) {
                $("#google-search").html(data.googleSearchCompiled);

                /****** Google Search ****/
                var size_li = $(".search-public-list li").length;
                x = 3;
                $('.search-public-list li:lt(' + x + ')').show();
                $('#load-more-google-search').click(function () {
                    x = (x + 3 <= size_li) ? x + 3 : size_li;
                    $('.search-public-list li:lt(' + x + ')').show();
                    if ($(".search-public-list li:visible").length == size_li) {
                        $('#load-more-google-search').hide();
                    }
                });
                /****** End Google Search ****/
            }
        });
        return false;

    }

    /****** Load More ****/
    $(document).on('click', '.load-more-kf', function (e) {

        var typePage = $(this).val();

        var pageNo = parseInt($('#' + typePage + '_page').val()) + 1;
        var keywordSearch = $('#search-resource').val();
        $('#' + typePage + '_page').val(pageNo);

        var url = appURL + "/ajax-load-more-resources"; // the script where you handle the form input
        $.ajax({
            type: "POST",
            url: url,
            data: $('#resourceFilter').serialize() + '&' + $.param({ 'typePage': typePage, 'pageNo': pageNo, 'searchResource': keywordSearch, '_token': token }), // serializes the form's elements.
            success: function (data) {

                var countT = $('#' + typePage + '_cnt').val();

                var pageCnt = parseInt(pageNo * recordCnt);

                if ((pageCnt + parseInt(recordCnt)) <= parseInt(countT)) {
                    $('#load_more_' + typePage).css('display', 'block');
                }
                else {
                    $('#load_more_' + typePage).css('display', 'none');
                }
                if (typePage == "news") {
                    typePage = "new-blog";
                }

                $('#' + typePage + '-div-inner').append(data);
                var i = 0;
                $(".filter-tabs-content .tab-pane.active a").each(function () {
                    if (i < 6) {
                        i++;
                    }
                    else {
                        i = 1;
                    }
                    $(this).addClass('scroll-' + i);
                });
                setTimeout(function () {
                    $(".filter-tabs-content .tab-pane.active").find(".scroll-1").removeClass("scroll-element");
                    $(".filter-tabs-content .tab-pane.active").find(".scroll-1").last().addClass("scroll-element");
                    $('html, body').animate({
                        scrollTop: $(".filter-tabs-content .tab-pane.active .scroll-element").offset().top - 200
                    }, 1500);
                }, 500);

            }
        });
    });

    /****** Auto Complete */
    $(document).ready(function () {
        //$('#resourceFilter').css('display', "block"); 
        var chgFilt = false;

        var path = appURL + "/autocomplete";

        $('#search-resource').autocomplete({
            source: function (query, process) {
                return $.get(path, { query: query.term }, function (data) {
                    var resp = $.map(data, function (obj) {
                        return obj.tag.replace(/\\/g, "");
                        // return obj.tag;
                    });
                    return process(resp);
                });
            },
            open: function (event, ui) {
                autocompleteElement = event.target;
                console.log(autocompleteElement)
                $('.ui-autocomplete').css('width', $(autocompleteElement).outerWidth() + 'px')
            },
            select: function (event, ui) {
                var label = ui.item.label;
                var value = ui.item.value;
                filterApply();
            }
        });

        /*****  Display tabs according to url  **/
        var url = window.location.href;
        var url = new URL(url);
        var tab = url.searchParams.get("tab");

        if (tab && $("." + tab + " .nav-link").length != 0) {
            $("." + tab + " .nav-link").trigger("click");
        } else {
            $("#all-nav").trigger("click");
        }

        // var windowUrlTab = location.search.split('tab=')[1];
        // if (windowUrlTab && $("." + windowUrlTab + " .nav-link").length != 0) {
        //     $("." + windowUrlTab + " .nav-link").trigger("click");
        // } else {
        //     $("#all-nav").trigger("click");
        // }

        $(".filter-tabs li a").click(function () {
            $(".filter-tabs li a").removeClass("active");
            $(this).addClass("active");
            $(".filter-tabs-content > div").removeClass("active");
            $(".filter-tabs-content > div").hide();
            $(".filter-tabs-content > ." + $(this).attr("data-relation")).show();
            $(".filter-tabs-content > ." + $(this).attr("data-relation")).addClass("active");
            AOS.refresh();
            AOS.refreshHard();
        });
        $(".fliter-by-section, .filter-tabs-content").hide();



        filterApply();

    });
}
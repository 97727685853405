if(lastUrlPara == "add-jkf-user") {
    $(document).ready(function () {
        $('#selectOrgani').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '350px',
            enableClickableOptGroups: true,
            nonSelectedText: 'Select organizations',
            maxHeight: 300
        });
        $('#selectOrgani1').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '350px',
            enableClickableOptGroups: true,
            nonSelectedText: 'Select organizations',
            maxHeight: 300
        });
    });

    $(document).ready(function () {
        var emailValidationPath = appURL + "/email-validation";
        $("#djy-add-form").validate({
            rules: {
                email: {
                    emailvalidate: true,
                    remote: {
                        url: emailValidationPath
                    }
                },
                firstname: {
                    lettersonly: true

                },
                lastname: { 
                    lettersonly: true
                }},
            messages: {
                email: {
                    emailvalidate: "Please enter a valid email address.",
                    remote: 'This email is already in use'
                }
            }
        });  
    });

    $('#sm_image_div input').on('change', function (event) {
        var elmentId = event.target.id;
        var ext = $('#' + elmentId).val().split('.').pop().toLowerCase();
        if ($.inArray(ext, ['png', 'jpg', 'jpeg']) == -1) {
            alert('invalid extension!');
            return false;
        } else {
            readURL(this, elmentId);
            // //Image Dump
            // $("#" + elmentId + "_filediv").html("<span><img src='" . $previewImage . "' class='img-fluid'></span>");
            // //Name Set
            // $("#" + elmentId + "_filename").val(jsonData.fileNmae);
        }
        //uploadSupportFile(elmentId, 'image');
    });

    function readURL(input, elmentId) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                //alert(e.target.result);
                $('#sm_image1_filediv').html("");
                $('#sm_image1_filediv').html('<img class="img-fluid" src="' + e.target.result + '">');
                $('#sm_image1_filediv').val(e.target.result);
                // $("#" + elmentId + "_filediv").html('<span><img class="img-fluid" src="' + e.target.result + '"></span>');
                // $("#" + elmentId + "_filename").val(jsonData.fileNmae);
                $("#pullRemove").show();
            }
            reader.readAsDataURL(input.files[0]);
        }
    }
    
    $("#bi_image1").change(function () {
        readURL(this);
    });

    $('#role_id').on('change', function(event) {
        if($(this).val() == 2)
        {
            $('.djy-fields-div').css({"display": "block"});
        }
        else
        {
            $('.djy-fields-div').css({"display": "none"});
        }
    });

    // function uploadSupportFile(elmentId, uploadType)
    // {
    //     var file_data = $('#' + elmentId).prop('files')[0];
    //     var token = $("#token").val();
    //     var form_data = new FormData();
    //     form_data.append('file', file_data);
    //     form_data.append('_token', token);
    //     form_data.append('uploadType', uploadType);
    //     $.ajax({
    //         url: appURL + "/upload-profile", // point to server-side PHP script 
    //         cache: false,
    //         contentType: false,
    //         processData: false,
    //         data: form_data,
    //         type: 'post',
    //         success: function (data) {
    //             var jsonData = JSON.parse(JSON.stringify(data));
    //             //Image Dump
    //             $("#" + elmentId + "_filediv").html(jsonData.previewImage);
    //             //Name Set
    //             $("#" + elmentId + "_filename").val(jsonData.fileNmae);
    //         }
    //     });
    // }

    $.validator.addMethod("emailvalidate", function (value, element) {
        return this.optional(element) || /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(value);
    });
}
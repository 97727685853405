$(window).load(function () {
    //AOS SCRIPT START
    AOS.init({
        duration: 1200,
        disable: 'phone, tablet, mobile',
    });
    AOS.refresh();
    AOS.refreshHard();
    //AOS SCRIPT END
});

$(document).ready(function () {    
    //TABS
    $('.filter-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        AOS.refresh();
        AOS.refreshHard();
    })
    //Header menu 
    if ($("#section-learn").length) {
        var hash = window.location.hash;
        if (hash) {
            $('html, body').animate({
                'scrollTop': $("h2" + hash).offset().top - 200
            }, 0);
            setTimeout(function () { $("a" + hash).trigger('click'); }, 0);
        }
        var menuElement = $(".header-navbar-nav li a");
        menuElement.each(function () {
            var addressValue = $(this).prop("hash").slice(1);
            if (addressValue == hash.slice(1)) {
                $(this).parent().addClass('has-active');
            }
            if (!addressValue) {
                $(this).parent().removeClass('has-active');
            }
            if (addressValue) {
                $(this).attr("id", addressValue)
                var clickMenuLink = $(this).attr('id');
                $("#" + clickMenuLink).click(function () {
                    menuElement.each(function () {
                        $(this).parent().removeClass('has-active');
                    })
                    $(this).parent().addClass('has-active');
                    $('html, body').animate({
                        scrollTop: $("h2#" + clickMenuLink).offset().top - 200
                    }, 1500);
                });
            }
        });
    }

    // Back to top button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').fadeIn('slow');
        } else {
            $('.back-to-top').fadeOut('slow');
        }
    });
    $('.back-to-top').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 1500);
        return false;
    });

    //Footer links
    $(".link-learn").click(function () {
        $(".header-navbar-nav li a#section-learn").trigger('click');
    });
    $(".link-engage").click(function () {
        $(".header-navbar-nav li a#section-engage").trigger('click');
    });
    $(".link-contribute").click(function () {
        $(".header-navbar-nav li a#section-contribute").trigger('click');
    });

    $(document).click(function (e) {
        if (!$(e.target).is('.global-search-collapse,.filterApplyUser')) {
            $('.global-search-collapse.collapse').collapse('hide');
        }
    });

    //Hide Header Search Cross
    var headerSearchInput = $(".global-search-collapse #header-search-resource");
    headerSearchInput.keyup(function () {
        var closeElement = $(this).siblings('.input-group-append').find('.close-collapse')
        closeElement.show();
        if (!$(this).val() == "") {
            closeElement.show();
        } else {
            closeElement.hide();
        }
    });

});

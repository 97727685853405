$(document).ready(function () {
    $(window).on("load", function () {
        var hash = window.location.hash;
        var id = hash.substring(hash.lastIndexOf('/') + 1);
        if(id) {
            if (id === "#subscribe-email") {
                $("#subscribe_email").focus();
            } else {
                $('html, body').animate({
                    'scrollTop': $(hash).offset().top - 74
                }, 1000);
            }
        }
        
    });

    $('#go').on('click', function () {
        var useremail = $('#subscribe_email').val();
        var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        
        if (useremail == "")
        {
            document.getElementById('showsubscribe_email').innerHTML = "Please insert e-mail id";
            $('#subscribe').modal('show');
        } else if (!(expr.test(useremail))) {
            document.getElementById('showsubscribe_email').innerHTML = "Please Enter Valid Email";
            $('#subscribe').modal('show');
        } else {
            $.ajax({
                url: appURL + '/subscribe-email',
                type: 'GET',
                data: {user_email: useremail,jkf_flag: 1},
                success: function (msg) {
                    //console.log("msg-------->",msg);
                    if (msg == 'success') {
                        document.getElementById('showsubscribe_email').innerHTML = "Your e-mail is added to our list";
                    } else if (msg == 'exists') {
                        document.getElementById('showsubscribe_email').innerHTML = "User is already subscribed.";
                    } else {
                        document.getElementById('showsubscribe_email').innerHTML = "System error...Please try again.";
                    }
                    $('#subscribe_email').val('');
                    $('#subscribe').modal('show');
                }
            });
        }

    });

});
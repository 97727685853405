//SCROLL SCRIPT START
$(document).ready(function () {
    $(".list-unstyled-wrap").niceScroll(".list-unstyled-scroll", {
        cursorborder: "",
        cursorcolor: "#c5192d",
        boxzoom: false,
        cursorwidth: "4px",
        cursorborderradius: '0px',
        enabletranslate3d: false,
        bouncescroll: false,
        autohidemode: false,
        dblclickzoom: false,
    });
});
//SCROLL SCRIPT END
if(lastUrlPara == "users-list") {
    $(document).ready(function () {
        $('#selectEntityAll').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            enableClickableOptGroups: true,
            nonSelectedText: 'Select type of partner',
            onDropdownShown: function(even) {
                this.$filter.find('.multiselect-search').focus();
            },
            maxHeight: 300
        });
        
        $('#selectRoles').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '350px',
            enableClickableOptGroups: true,
            nonSelectedText: 'Select roles',
            onDropdownShown: function(even) {
                this.$filter.find('.multiselect-search').focus();
            },
            onSelectAll: function () {
                changeFilter();
            },
            onDeselectAll: function () {
                changeFilter();
            },
            maxHeight: 300
        });
        
        $('#selectOrgsUser').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '350px',
            enableClickableOptGroups: true,
            nonSelectedText: 'Select organisations',
            onDropdownShown: function(even) {
                this.$filter.find('.multiselect-search').focus();
            },
            onSelectAll: function () {
                changeFilter();
            },
            onDeselectAll: function () {
                changeFilter();
            },
            maxHeight: 300
        });
        
        //export cols
        $("#all_columns1").click(function(){
            if ($(this).is(':checked')){
                $(".togglecol").each(function(){
                    $(this).prop('checked', true);
                });
            } else{
                $(".togglecol").each(function(){
                    $(this).prop('checked', false);
                });
            }
        });
        $(".togglecol").click(function(){
            if (!$(this).is(':checked') && $("#all_columns1").is(':checked')){
                $("#all_columns1").prop('checked', false);
            }
        });
        $("#all_columns1").trigger('click');
        
        $("#all_columns2").click(function(){
            if ($(this).is(':checked')){
                $(".togglecol1").each(function(){
                    $(this).prop('checked', true);
                });
            } else{
                $(".togglecol1").each(function(){
                    $(this).prop('checked', false);
                });
            }
        });
        $(".togglecol1").click(function(){
            if (!$(this).is(':checked') && $("#all_columns2").is(':checked')){
                $("#all_columns2").prop('checked', false);
            }
        });
        $("#all_columns2").trigger('click');
        
        $("#load_more_users").click(function (e) {
            var url = appURL + "/ajax-filter-users";
            var val1 = parseInt($("#currentCountUser").val())+1;
            $("#currentCountUser").val(val1);
            var total_val = 10;
            $("#userCSVFilter").val($("#userFilters").serialize());
            $.ajax({
                type: 'post',
                url: url,
                data: $("#userFilters , #sortFunctionsUser").serialize(), // serializes the form's elements.
                success: function (response) {
                    var currentCount = response.split('#########');
                    var totalCount = parseInt(currentCount[2]);
                    $('#totalCountUser').val(totalCount);
                    $('#usersCountAll').html(currentCount[1]+" out of "+currentCount[2]);
                    if (currentCount[0] != '') {
                        $("#listing_users").append(currentCount[0]);
                        if(parseInt(currentCount[1]) >= parseInt(currentCount[2]))
                        {
                            $("#load_more_users").hide();
                        }
                    } else {
                        if(parseInt(currentCount[1]) >= parseInt(currentCount[2]))
                            $("#load_more_users").hide();
                    }
                }
            });
        });
        
        $(document).on('change', '.filterApplyUser', function () {
                changeFilter();
        });

        function changeFilter()
        {
            $("#userCSVFilter").val($("#userFilters").serialize());
            $("#currentCountUser").val(0);
            var url = appURL + "/ajax-filter-users";
            $.ajax({
            type: 'post',
            url: url,
            data: $("#userFilters, #sortFunctionsUser").serialize(), // serializes the form's elements.
            success: function (response) {
                var currentCount = response.split('#########');
                var totalCount = parseInt(currentCount[2]);
                $('#totalCountUser').val(totalCount);
                //$('#currentCount').val(currentCount[1]);
                $('#clear-users').show();
                if (currentCount[0] != '') {
                    
                    $("#listing_users").html(currentCount[0]);
                    $('#usersCountAll').html(currentCount[1]+" out of "+currentCount[2]);
                    if(parseInt(currentCount[1]) >= parseInt(currentCount[2]))
                    {
                        $("#load_more_users").hide();
                    }
                    else
                    {
                        $("#load_more_users").show();
                    }
                } else {
                        $("#listing_users").html('<tr><td colspan = "10">No records found</td></tr>');
                        $('#usersCountAll').html("0 out of 0");
                        if(parseInt(currentCount[1]) >= parseInt(currentCount[2]))
                        {
                            $("#load_more_users").hide();
                        }
                }
                }
            });
        }

        $('#orgFilters').on('reset', function() {
            $('#selectEntityAll').val('');
            $('#selectEntityAll').multiselect('refresh');
            $('#clear-orgs1').hide();
        });

        $('.sorting-tbl').on('click', function(event){
            $(event.target).siblings(".sorting-tbl").removeClass("sorting_desc sorting_asc");
            if($(event.target).hasClass('sorting_asc')){
                $(event.target).removeClass('sorting_asc').addClass('sorting_desc');
                $("#sortOrderUser").val("desc");
                $("#sortByUser").val($(this).attr("data-sort"));
            }else{
                $(event.target).addClass('sorting_asc').removeClass('sorting_desc');
                $("#sortOrderUser").val("asc");
                $("#sortByUser").val($(this).attr("data-sort"));
            }
            changeFilter();
        });
    
        $(document).on('click', ".unsubscribe-email", function(event){
            if(confirm("Are you sure you want to unsubscribe this user?")){
                var emailId = $(this).attr("data-email");
                var url = appURL + "/unsubscribe-email";
                $.ajax({
                type: 'post',
                url: url,
                data: {user_email: emailId}, // serializes the form's elements.
                success: function (response) {
                    console.log(response);
                    changeFilter();
                }
                });
            }
        });
    
    
        $(document).on('click', ".acceptFocal", function(event){
            var eleiID = $(this).attr('id');
            var userData = eleiID.split('_');
            if(userData[2]=='Y')
                var confirmchk = confirm('Do you really want to accept the focal point request?');
            else
                var confirmchk = confirm('Do you really want to reject the focal point request?');
            if(confirmchk)
            {
                $.ajax({
                    url: appURL +'/update_role',
                    type: 'GET',
                    data: {user_id:userData[1],user_role:userData[2]},
                    success:function(msg){
                        changeFilter();
                    }
                });
            }
        });
    });
}

$(document).ready(function () {
    // RESTYLE THE DROPDOWN MENU
    $('#google_translate_element').on("click", function () {
        // Change font family and color
        $("iframe").contents().find(".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *")
            .css({
                'color': '#EBF5FD',
                //'width':'100%'
            });
        // Hide languages
        // $("iframe").contents().find('.goog-te-menu2-item div span:contains(French)').parent().hide();
        // $("iframe").contents().find('.goog-te-menu2-item div span:contains(Spanish)').parent().hide();
        // Change menu's padding
        $("iframe").contents().find('.goog-te-menu2-item-selected').css('display', 'none');
        // Change menu's padding
        $("iframe").contents().find('.goog-te-menu2').css('padding', '20px 40px');
        // Change the padding of the languages
        $("iframe").contents().find('.goog-te-menu2-item div').css('padding', '10px 0px 10px 5px');
        if ($(window).innerWidth() <= 1024) {
            $("iframe").contents().find('.goog-te-menu2').css('padding', '0px');
            $("iframe").contents().find('.goog-te-menu2-item div').css('padding', '10px 15px');
        }
        $("iframe").contents().find('.goog-te-menu2-item div').css('background', '#002554');
        $("iframe").contents().find('.goog-te-menu2-item div').css('color', '#EBF5FD');
        // Change the width of the languages
        //$("iframe").contents().find('.goog-te-menu2-item').css('width', '100%');
        //$("iframe").contents().find('td').css('width', '100%');
        // Change hover effects
        $("iframe").contents().find(".goog-te-menu2-item div").hover(function () {
            $(this).css('background', '#c5192d').find('span.text').css('color', '#EBF5FD');
        }, function () {
            $(this).css('background', '#002554').find('span.text').css('color', '#EBF5FD');
        });
        $("iframe").contents().find(".goog-te-menu2-item div").on('touchstart', function (e) {
            $(this).css('background', '#c5192d').find('span.text').css('color', '#EBF5FD');
        });
        $("iframe").contents().find(".goog-te-menu2-item div").on('touchend', function (e) {
            $(this).css('background', '#002554').find('span.text').css('color', '#EBF5FD');
        });
        // Change Google's default blue border
        $("iframe").contents().find('.goog-te-menu2').css('border', 'none');
        $("iframe").contents().find('.goog-te-menu2').css('background', '#002554');
        // Change the iframe's box shadow
        $(".goog-te-menu-frame").css('background', '#002554');
        // Change the iframe's size and position?
        $(".goog-te-menu-frame").css({
            'height': $("iframe").contents().find('.goog-te-menu2').outerHeight() - 40 + 'px',
            'min-height': '440px',
            'width': $(".container").outerWidth() - 100 + 'px',
            'top': '30px',
            'left': '0px',
            'overflow': 'auto',
            'box-shadow': 'none',
            'margin': 'auto',
            'bottom': 'auto',
            'right': '0'
        });
        // Change iframes's size
        $("iframe").contents().find('.goog-te-menu2').css({
            'height': $("iframe").contents().find('.goog-te-menu2').outerHeight() - 40 + 'px',
            'min-height': '440px',
            'width': $(".container").outerWidth() - 100 + 'px', 'overflow': 'auto'
        });
    });
});

if (lastUrlPara == "setting") {

    $(document).ready(function () {

        $('#setting_publications').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_publications_option).append('<ul><li id="publications_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#publications_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_publications_option).find("ul").remove();
                selectAllEle = $('#setting_publications').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_publications_option).append('<ul><li id="publications_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_publications_option).find("ul").remove();
            }
        });

        $('#setting_policies').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_policies_option).append('<ul><li id="policies_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#policies_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_policies_option).find("ul").remove();
                selectAllEle = $('#setting_policies').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_policies_option).append('<ul><li id="policies_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_policies_option).find("ul").remove();
            }
        });


        $('#setting_platform').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_platform_option).append('<ul><li id="platform_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#platform_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_platform_option).find("ul").remove();
                selectAllEle = $('#setting_platform').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_platform_option).append('<ul><li id="platform_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_platform_option).find("ul").remove();
            }
        });


        $('#setting_tools').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_tools_option).append('<ul><li id="tools_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#tools_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_tools_option).find("ul").remove();
                selectAllEle = $('#setting_tools').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_tools_option).append('<ul><li id="tools_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_tools_option).find("ul").remove();
            }
        });


        $('#setting_multimedia').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_multimedia_option).append('<ul><li id="multimedia_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#multimedia_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_multimedia_option).find("ul").remove();
                selectAllEle = $('#setting_multimedia').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_multimedia_option).append('<ul><li id="multimedia_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_multimedia_option).find("ul").remove();
            }
        });


        $('#setting_event').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_event_option).append('<ul><li id="event_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#event_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_event_option).find("ul").remove();
                selectAllEle = $('#setting_event').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_event_option).append('<ul><li id="event_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_event_option).find("ul").remove();
            }
        });

        $('#setting_news').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            maxHeight: 350,
            enableClickableOptGroups: true,
            nonSelectedText: 'Check an option!',
            onChange: function (options, selected) {
                var value_text = $(options).text();
                var value_id = $(options).val();

                if (selected) {
                    $(selected_news_option).append('<ul><li id="news_' + value_id + '">' + value_text + '</li></ul>');
                } else {
                    $("#news_" + value_id).remove();
                }
            },
            onSelectAll: function () {
                $(selected_news_option).find("ul").remove();
                selectAllEle = $('#setting_news').next().find(".multiselect-all").nextAll('li');
                selectAllEle.each(function () {
                    getSelectedLbl = $(this).find('.checkbox').text();
                    getSelectedValue = $(this).find('input').val();
                    $(selected_news_option).append('<ul><li id="news_' + getSelectedValue + '">' + getSelectedLbl + '</li></ul>');
                })
            },
            onDeselectAll: function () {
                $(selected_news_option).find("ul").remove();
            }
        });

    });

    $(document).ready(function () {
        var max_fields = 10; //maximum input boxes allowed
        var wrapper = $(".input_fields_wrap"); //Fields wrapper
        var add_button = $(".add_field_button"); //Add button ID

        var x = 1; //initlal text box count
        $(add_button).click(function (e) { //on add input button click
            e.preventDefault();
            if (x < max_fields) { //max input box allowed
                x++; //text box increment
                $(wrapper).append('<div class="reason-list">Reason: <input class="form-control" type="text" name="reason[]"/>&nbsp;<a href="#" class="remove_field btn btn-primary">Remove</a></div>'); //add input box
            }
        });

        $(wrapper).on("click", ".remove_field", function (e) { //user click on remove text
            e.preventDefault();
            $(this).parent('div').remove();
            x--;
        })

        $(".remove_existing_reason").click(function (e) {
            var reasonid = $(this).parent().find('#reason_id').val();
            if (reasonid != undefined) {

                $.ajax({
                    type: "GET",
                    url: "<?php echo url('contactus_reason_del'); ?>",
                    data: { id: reasonid },
                    success: function (result) {

                    }
                });
            }
        });

        $('body').on('click', '.btnRemove', function () {
            $(this).parent('div.con').remove()
        });
        $(".addSpan").click(function () {
            var noofappend = $("#no_of_reminder__monitoring_cycle").val();
            var uploadedDocCount = $("#dateTab .con").length;
            if (noofappend != "") {
                if (noofappend > uploadedDocCount) {
                    var i = $("#dateTab input").length + 1;
                    $("#dateTab").append($("<div class='con'><label>Date</label><input class='form-control getDate' id='id_" + i + "' type='text' name='date_reminderer_monitoring_cycle[]' /><button class='btnRemove btn btn-primary custom-btn-bg' value='Remove'>Remove</button></div>")); //reinitiate the datepicker
                    $(".getDate").datepicker();
                }
            }
        });
        $(".getDate").datepicker();
    });
}
$(document).ready(function () {
  $('#knowledge .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: false
      },
      1000: {
        items: 2,
        nav: true,
        loop: false,
      }
    }
  })
  $('#publications .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    items: 4,
    stagePadding: 5,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
      }
    }
  })
  $('#tools .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    stagePadding: 5,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
      }
    }
  })
  $('#news .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    stagePadding: 5,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
      }
    }
  })
  $('#engage .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 4,
        nav: true,
        loop: false,
      }
    }
  })
  $('#multimedia .owl-carousel,#event_slider .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    stagePadding: 5,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 3,
        nav: true,
      }
    }
  })
  $('#logoslider .owl-carousel').owlCarousel({
    loop: true,
    margin: 20,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 3,
        nav: true
      },
      1000: {
        items: 6,
        nav: true,
        loop: false,
      }
    }
  })


  $('.details-img-carousel .owl-carousel').owlCarousel({
    loop: true,
    margin: 0,
    items: 1,
    nav: true,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true
  })


})

if(secondUrlPara == "edit-user") {
  $(document).ready(function () {
    $('#useractive').on('click',function(){
        var useractive =   $('#useractive').val(); 
        if(useractive=='N'){
          var active="Activate user";
          var val='Y';
          var status='Inactive';                
        }
        else{
          var active="Deactivate user";
          var val='N';
          var status='Active'; 
        } 
        var useremail = $('#useremail').val();
            $.ajax({
              url: appURL + '/update_user_status',
              type: 'GET',                    
              data: {useractive:useractive,user_email:useremail},
              success:function(msg){
                  $("#useractive").attr('value', val);                       
                  $("#useractive").text(active);
                  $("#active").text(status);
              }
            });
      });

      $('#selectboxid').on('change',function(){
          var role_id =   $('#selectboxid').val();
          var useremail = $('#useremail').val();
          $.ajax({
            url: appURL + '/update_role',
            type: 'GET',
            data: {role_id:role_id,user_email:useremail},
            success:function(msg){
                //alert("success");
            }
          });
      });
  }); 
}
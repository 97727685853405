
$(document).ready(function () {
  var url = $(location).attr('href'),
  parts = url.split("/"),
  last_part = parts[parts.length-1];

  if(last_part == "partners") {
    $(".nav-item-partner").addClass("has-active");
  }
  
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
    $("#login-form").validate({
        rules: {
          email: {
            required: true,
            email: true
          },
          password: {
            required: true,
            //pwcheck: true,
            //minlength: 8
          }
        },
        messages: {
          email: {
            required: "Please enter a email address",
            email: "Please enter a valid email address"
          },
          password: {
            required: "Please enter a password",
            //pwcheck: "A Password must contain minimum of eight characters, One letter, One number, One special character",
            //minlength: "A Password must contain minimum of eight characters, One letter, One number, One special character"
          }
        },
        
        submitHandler: function (form) { // for demo
            var url = appURL + "/login";
            //var url = "http://localhost/ilodjy/public/api/v1/login"; // the script where you handle the form input
            $.ajax({
                type: "POST",
                url: url,
                data: $("#login-form").serialize(), // serializes the form's elements.
                success: function (data)
                {
                    //console.log("data=========>",data);
                    var response = JSON.parse(data);
                    console.log("response.Status------>", response.Status);
                    if (response.Status == true) {
                        $('#login-msg').removeClass('error');
                        $('#login-msg').html(response.Message);
                        $('#login-msg').show();
                        //setTimeout(function() { $("#login-msg").hide(); }, 20000);
                        //console.log("appURL------>", appURL);
                        window.location = appURL;
                        //console.log("data.Message------>", response.Message);
                    } 
                    if (response.Status == false) {
                      $('#login-msg').html(response.Message)
                      $('#login-msg').show();
                      //setTimeout(function() { $("#login-msg").hide(); }, 20000);
                      //console.log("error.Message------>", response.Message);
                  }
                },
                error: function (error) {
                  var response = JSON.parse(error);
                  console.log("response.Status------>", response.Status);
                  if (response.Status == false) {
                      $('#login-msg').html(response.Message)
                      
                      setTimeout(function() { $("#login-msg").hide(); }, 20000);
                      console.log("error.Message------>", response.Message);
                  }
              }
            });
            event.preventDefault();
            return false;
        }
    });

    $.validator.addMethod("pwcheck",
        function(value, element) {
            return /^[A-Za-z0-9\d=!\-@._*]+$/.test(value);
    });
});
$('.sorting-tbl-1').on('click', function (event) {
    $(event.target).siblings(".sorting-tbl-1").removeClass("sorting_desc sorting_asc");
    if ($(event.target).hasClass('sorting_asc')) {
        $(event.target).removeClass('sorting_asc').addClass('sorting_desc');
        $("#sortOrderOrg").val("desc");
        $("#sortByOrg").val($(this).attr("data-sort"));
    } else {
        $(event.target).addClass('sorting_asc').removeClass('sorting_desc');
        $("#sortOrderOrg").val("asc");
        $("#sortByOrg").val($(this).attr("data-sort"));
    }

    changeOrgFilter();
});

$(document).on('change', '.filterApply', function () {
    changeOrgFilter();
});

$(document).on('click', '.organizationtab', function () {
    console.log("test organizationtab");
    $(".org-loader").removeClass("d-none");
    changeOrgFilter();
});

function changeOrgFilter()
{
    $("#currentCount").val(0);
    var url = appURL + "/ajax-filter-orgs";
    $("#orgCSVFilter").val($("#orgFilters").serialize());

    $.ajax({
        type: 'post',
        url: url,
        data: $("#orgFilters, #sortFunctionsOrg").serialize(), // serializes the form's elements.
        success: function (response) {

            var currentCount = response.split('#########');
            var totalCount = parseInt(currentCount[2]);
            $('#totalCount').val(totalCount);
            $('#clear-orgs1').show();
            //console.log("totalCount------>",totalCount);
            if (currentCount[0] != '') {
                $(".org-loader").addClass("d-none");
                $("#listing_orgs").html(currentCount[0]);
                $('#orgsCountAll').html(currentCount[1]+" out of "+currentCount[2]);
                if(parseInt(currentCount[1]) >= parseInt(currentCount[2]))
                {
                    $("#load_more_org").hide();
                }
                else
                {
                    $("#load_more_org").show();
                }
            } else {
                $("#listing_orgs").html('<tr><td colspan = "5">No records found</td></tr>');
                $('#orgsCountAll').html("0 out of 0");
                if(parseInt(currentCount[1]) >= parseInt(currentCount[2]))
                {
                    $("#load_more_org").hide();
                }
                else
                {
                    $("#load_more_org").show();
                }
            }
        }
    });
}

$("#load_more_org").click(function (e) {
    var url = appURL + "/ajax-filter-orgs";
    var val1 = parseInt($("#currentCount").val())+1;
    $("#currentCount").val(val1);
    $("#orgCSVFilter").val($("#orgFilters").serialize());
    
    var total_val = 10;
   
    $.ajax({
        type: 'post',
        url: url,
        data: $("#orgFilters").serialize(), // serializes the form's elements.
        success: function (response) {
            var currentCount = response.split('#########');
            var totalCount = parseInt(currentCount[2]);
            $('#totalCount').val(totalCount);
            //$('#currentCount').val(currentCount[1]);
            $('#orgsCountAll').html(currentCount[1]+" out of "+currentCount[2]);
            if (currentCount[0] != '') {
                
                $("#listing_orgs").append(currentCount[0]);
                if(parseInt(currentCount[1]) >= $('#totalCount').val())
                {
                    $("#load_more_org").hide();
                }
            } else {
                if(parseInt(currentCount[1]) >= $('#totalCount').val())
                    $("#load_more_org").hide();
            }
        }
    });
});
$(document).ready(function () {

    $('#forgotModal').on('shown.bs.modal', function () {
        $("body").addClass("modal-open");
    });

    $('#forgotModal').on('hidden.bs.modal', function () {
        $("body").removeClass("modal-open");
    });

    $("#submit_email").click(function (event) {
        
        //$("#submit_email").attr("disabled",true);
        var url = appURL + "/password/email"; // the script where you handle the form input
        $.ajax({
            type: "POST",
            url: url,
            data: $("#forgotPasswordForm").serialize(), // serializes the form's elements.
            success: function (data)
            {
                if (data.Status == true)
                {
                    $('#errorLoginforgot').css({"display": "block"});
                    $('#errorLoginforgot').html(data.Message);
                    $('.formele').css("display", "none");
                    $('#email-msg').css("display", "none");
                    $('.instruction').css("display", "none");
                }
            },
            error: function (error) {
                if (error.responseJSON.Message == "The email must be a valid email address.") {
                    $('#email-msg').html(error.responseJSON.Message);
                } else {
                    $('#errorLoginforgot').css({"display": "block"});
                    $('#errorLoginforgot').html(error.responseJSON.Message);
                    $('#email-msg').css("display", "none");
                }
            }
        });
        event.preventDefault();
        return false;

    });
});
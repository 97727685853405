$('.form-control-multiselect').multiselect({
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    enableFullValueFiltering: false,
    includeSelectAllOption: true,
    enableCollapsibleOptGroups: true,
    buttonWidth: '100%',
    enableClickableOptGroups: true,
    maxHeight: 300
});

if (lastUrlPara == "my-resources") {
    $(document).ready(function () {

        $('#resource_types').multiselect({
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            enableFullValueFiltering: false,
            includeSelectAllOption: true,
            buttonWidth: '100%',
            enableClickableOptGroups: true,
            nonSelectedText: 'Select Resource Type',
            onDropdownShown: function (even) {
                this.$filter.find('.multiselect-search').focus();
            },
            onSelectAll: function () {
                getData();
            },
            onDeselectAll: function () {
                getData();
            },
            maxHeight: 300
        });




        $('#resource_types').change(function () {
            getData();
        });

        $('.sorting-tbl').on('click', function (event) {
            $(event.target).siblings(".sorting-tbl").removeClass("sorting_desc sorting_asc");
            if ($(event.target).hasClass('sorting_asc')) {
                $(event.target).removeClass('sorting_asc').addClass('sorting_desc');
                $("#sortOrder").val("desc");
                $("#sortBy").val($(this).attr("data-sort"));
            } else {
                $(event.target).addClass('sorting_asc').removeClass('sorting_desc');
                $("#sortOrder").val("asc");
                $("#sortBy").val($(this).attr("data-sort"));
            }
            $("#loader").show();
            applyFilterOnAllEvents(1, 1);
        });

        $("#keywordUser").blur(function () {
            getData();
        });

        $("input[name='checkStatusResource[]']").change(function () {
            getData();
        });

        $("#exportResources").click(function () {
            window.location.href = appURL + "/my-resources-export?" + $("#eventFilterAll").serialize();
        });

        function getData() {

            $("input[name='page_number']").val("0");

            var page_number = $("input[name='page_number']").val();
            var page_data = $("input[name='page_page']").val();

            $.ajax({
                type: "GET",
                url: appURL + "/my-resources-ajax",
                data: $("#eventFilterAll").serialize(), // serializes the form's elements.
                success: function (data) {
                    var dataR = data.split('########');
                    //console.log("dataR=====>", dataR);
                    $("#allEventsBody").html(dataR[0]);

                    var cntRec = dataR[1];
                    var totalCntRec = dataR[2];
                    if (parseInt(totalCntRec) < page_data) {
                        $('#allEventCount').html(parseInt(cntRec));
                        $('#allEventTotalCount').html(parseInt(cntRec));
                    } else {
                        $('#allEventCount').html(page_data);
                        $('#allEventTotalCount').html(parseInt(totalCntRec));
                    }
                    
                    if (parseInt(dataR[1]) >= parseInt(page_data)) {
                        $('#load_more_all_events').css('display', 'block');
                    } else {
                        $('#load_more_all_events').css('display', 'none');
                    }
                }
            });

        }


        function appendData() {

            var page_number = $("input[name='page_number']").val();
            var page_data = $("input[name='page_page']").val();

            $.ajax({
                type: "GET",
                url: appURL + "/my-resources-ajax",
                data: $("#eventFilterAll").serialize(), // serializes the form's elements.
                success: function (data) {
                    var dataR = data.split('########');
                    //console.log("dataR=====>", dataR);
                    $("#allEventsBody").append(dataR[0]);

                    var cntRec = dataR[1];

                    $('#allEventCount').html(parseInt(cntRec) + parseInt($('#allEventCount').html()));
                    var pageCnt = parseInt(page_number * page_data);

                    if (parseInt(dataR[1]) >= parseInt(page_data)) {
                        $('#load_more_all_events').css('display', 'block');
                    } else {
                        $('#load_more_all_events').css('display', 'none');
                    }
                }
            });

        }

        $("#load_more_all_events").click(function () {

            var page_number = $("input[name='page_number']").val();
            page_number++;
            $("input[name='page_number']").val(page_number);

            appendData();
        });

        $(document).on("click", ".delete-link", function () {

            if (confirm('Are you sure you want to delete this?')) {
                return true;
            } else {
                return false;
            }
        });

        //    $.ajax({
        //        type: "GET",
        //        url: appURL+"/my-resources-ajax",
        //        data: $("#eventFilterAll").serialize(), // serializes the form's elements.
        //        success: function (data)
        //        {
        //            var dataR = data.split('########');
        //            var cntRec = parseInt($('#allEventCount').html()) + parseInt(dataR[1]);
        //            $('#allEventCount').html(cntRec);
        //
        //            var page_number = $("input[name='page_number']").val();
        //            page_number++;
        //
        //            if ((dataR == ',0')) {
        //                $("#load_more_all_events").hide();
        //                $("#allEventsBody").append("<div>No Data Found</div>");
        //            }
        //
        //            if (cntRec < 40) {
        //                $("#load_more_all_events").hide();
        //            }
        //
        //            $("#allEventsBody").append(dataR[0]);
        //        }
        //    });
        appendData();

    });
}

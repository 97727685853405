$(document).ready(function () {
    //console.log("lastUrlPara---->", lastUrlPara);
    if(lastUrlPara == "?contact=") {
        $('#contact-us-modal').modal('show');
    }
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    var orglist = appURL + "/get-contact-orgs";
    $("#org-suggesstion-box").hide();
    $(document).mouseup(function (e) {
        var orgContainer = $("#org-suggesstion-box");
        if (!orgContainer.is(e.target) && orgContainer.has(e.target).length === 0) {
            orgContainer.hide();
        }
    });
    $("#contact_org").keyup(function () {
        if ($(this).val() != "") {
            $.ajax({
                type: "POST",
                url: orglist,
                data: 'keyword=' + $(this).val(),
                success: function (data) {
                    $("#org-suggesstion-box").show();
                    $("#org-suggesstion-box").html(data);

                    //$("#selectorg").css("background", "#FFF");
                }
            });
        }
    });

    $(document).on('click', ".contact_org", function (e) {
        var id = e.target.id;
        var orgname = decodeHtml($("#" + id).html());
        $("#contact_org").val(orgname);
        $("#org-suggesstion-box").hide();
    });

    $("#contactus-form").validate({
        rules: {
            contact_name: {
                required: true
            },
            contact_email: {
                required: true,
                contact_email_validate: true
            },
            contact_query: {
                required: true,
                maxlength: 200
            }
        },
        messages: {
            contact_name: {
                required: "Please enter a name",
            },
            contact_email: {
                required: "Please enter a email address",
                contact_email_validate: "Please enter a valid email address"
            },
            contact_query: {
                required: "Please enter a query",
                maxlength: "Please enter a valid text"
            }
        },

        submitHandler: function (form) { // for demo
            var url = appURL + "/contactus-save";
            $.ajax({
                type: "POST",
                url: url,
                data: $("#contactus-form").serialize(), // serializes the form's elements.
                success: function (data) {
                        $('#contactus-form').hide();
                        $('#contact_success_msg').text("Query received! We will get back to you soon.")
                        //$('#modal-div').show();
                    
                    //window.location.reload();
                },
                error: function (error) {
                    var response = JSON.parse(error);
                    if (response.Status == false) {
                        $('#login-msg').html(response.Message)

                        setTimeout(function () { $("#login-msg").hide(); }, 20000);
                    }
                }
            });
            event.preventDefault();
            return false;
        }
    });

    $.validator.addMethod("contact_email_validate",
    function (value, element) {
        return this.optional(element) || /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(value);
    });
});